import { FaGripLinesVertical } from 'react-icons/fa'; // Example of a fancy icon
import Dropdown from 'components/Dropdown/Dropdown';
import styles from '../websiteManagement.module.scss';
import Dots from 'assets/icons/vertical-dot.svg';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Option } from 'components/Dropdown/Option';

const SingleCategory = ({ curated, onDelete, onRemove, item, setCurrentItem, onCategoryView }) => {
  const navigate = useNavigate();

  const categoryType = () => {
    switch (item?.show_in) {
      case 'Centres page':
        return <div className={styles.single_category__type__centers}>Centres</div>;
      case 'Homepage (blue frame)':
        return <div className={styles.single_category__type_p}>Homepage</div>;
      default:
        return <div className={styles.single_category__type__package}>Package</div>;
    }
  };

  return (
    <div className={styles.single_category} onClick={onCategoryView}>
      <div className={styles.single_category__caption}>
        <div className={styles.single_category__caption__category}>
          <h6 className={styles.single_category__caption__category_name}>
            {item?.name?.length > 20 ? `${item?.name?.slice(0, 20)}...` : item.name}
          </h6>
          <p className={styles.single_category__caption__category_nametitle}>Category name</p>
        </div>

        <p className={styles.single_category__view} onClick={(e) => e.stopPropagation()}>
          <Dropdown
            content={
              <div>
                {!curated ? (
                  <Option
                    onClick={() => navigate(`/website-management/home-package/edit/${item.id}`)}
                  >
                    Edit
                  </Option>
                ) : null}
                {!curated ? <Option onClick={() => onDelete()}>Delete</Option> : null}
                {curated ? (
                  <Option
                    onClick={() => {
                      onRemove();
                      setCurrentItem(item);
                    }}
                  >
                    Remove
                  </Option>
                ) : null}
              </div>
            }
          >
            <img src={Dots} alt="dot" />
          </Dropdown>
        </p>
      </div>

      <div className={styles.single_category__type}>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <div>
            {categoryType()}
            <p className={styles.single_category__type_nametitle}>Category type</p>
          </div>
          <div>
            <p className={styles.single_category__type_count}>{item?.parameters}</p>
            <p className={styles.single_category__type_nametitle}>Parameters</p>
          </div>
        </div>
      </div>
    </div>
  );
};

SingleCategory.propTypes = {
  curated: PropTypes.bool,
  onRemove: PropTypes.func,
  onDelete: PropTypes.func,
  setCurrentItem: PropTypes.func,
  item: PropTypes.any,
  onCategoryView: PropTypes.func
};

export default SingleCategory;
